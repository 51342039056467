import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
// // import { GatsbyImage } from "gatsby-plugin-image"
// import { Fragment } from "react"

import { Hero, Cards } from "../components/template"
import Seo from "../components/seo"
export default function Page({
  pageContext: { data },
  data: {
    allSanityService: { nodes: services },
  },
}) {
  // console.log(data)
  return (
    <Layout>
      <Seo
        title={data.title || data.name}
        // schemaMarkup={schema}
        // description={property.highlight}
      />
      <Hero property={data} className="bg-black bg-opacity-50" />
      <Cards className="pt-12" link="/" list={services} />
    </Layout>
  )
}

export const query = graphql`
  query {
    allSanityService(sort: { order: ASC, fields: order }) {
      nodes {
        name
        slug {
          _key
          _type
          current
        }
        image {
          asset {
            gatsbyImageData
          }
        }
      }
    }
  }
`
